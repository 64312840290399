<template>
    <div class="p-4">
        <top-search></top-search>

        <div class="w-full flex justify-between my-4 p-4">
            <label for="charges" class="font-semibold capitalize">States</label>
            <div class="flex justify-between">
                <button class="px-5 py-2 rounded-lg hover:outline">
                    Export
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </button>
                <!-- <button @click="leaving_modal = false, charge_modal = true" class="ml-4 bg-brown-default text-gray-50 px-5 py-2 rounded-lg">Add Charge</button> -->
            </div>
        </div>

        <div class="w-full p-5">
            <div class="bg-white flex justify-between p-4">
                <div>
                    <input type="text" placeholder="Search" v-model="search" class="ml-2 outline-none py-1 px-2 text-md border-2 rounded-md">
                </div>
                
                <div>
                    <input type="date" class="ml-2 outline-none py-1 px-2 text-md border-2 rounded-md">
                </div>
            </div>
        </div>

        <div v-if="states" class="w-full my-12 px-4 fade-in">
            <div class=" flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded">                
                <div class="block w-full h-96 overflow-auto">
                    <table class="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                <th class="px-4 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">#</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Name</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Status</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Total Cities </th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Date Updated</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr class="bg-white" v-for="(state, index) in states" :key="state.id">
                                <td class="border-t-0 rounded-md pl-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                                    <span class="font-bold"> {{ index + 1 }} </span>
                                </td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">{{ state.name }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left uppercase">{{ state.status }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">{{ state.cities.length }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">{{ moment(state.updated_at).format('ddd, DD MMM YYYY') }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                    <!-- <button @click="id = state.id, status = state.status, stateUpdateRequest()">On</button> -->
                                    <div class="relative rounded-full w-12 h-6 transition duration-200 ease-linear"
                                        :class="[state.status === 'available' ? 'bg-green-400' : 'bg-red-400']">
                                        <label for="toggle"
                                            class="absolute left-0 bg-white border-2 mb-2 w-6 h-6 rounded-full transition transform duration-100 ease-linear cursor-pointer"
                                            :class="[state.status === 'available' ? 'translate-x-full border-green-400' : 'translate-x-0 border-red-400']"></label>
                                        <input type="checkbox" id="toggle" name="toggle"
                                            class="appearance-none w-full h-full active:outline-none focus:outline-none"
                                            @click="id = state.id, status = state.status, stateUpdateRequest(), state.status === 'unavailable' ? state.status = 'available' : state.status = 'unavailable'"/>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <square-grid v-else class="absolute top-1/2 left-1/2"></square-grid>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TopSearch from '@/components/TopSearch.vue'
import SquareGrid from '@/components/loaders/SquareGrid.vue'
import moment from 'moment'

export default {
    name: 'State',
    components: {
        TopSearch,
        SquareGrid
    },
    data() {
        return {
            id: '',
            status: '',
            moment: moment,
            toggle: 0
        }
    },
    computed: {
        ...mapGetters({states: 'statesModule/getStates'})
    },
    methods: { 
        stateUpdateRequest() {
            this.$store.dispatch('statesModule/stateUpdateRequest', { id: this.id, status: this.status })
        }
    }
}
</script>